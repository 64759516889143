import { template as template_711b1813dd72415b8c3afa094c991cf2 } from "@ember/template-compiler";
const FKText = template_711b1813dd72415b8c3afa094c991cf2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
