import { template as template_db51553efb414839850b5e4a69245f22 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_db51553efb414839850b5e4a69245f22(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
