import { template as template_770d51c281374202b8ab7ba32c190a1a } from "@ember/template-compiler";
const WelcomeHeader = template_770d51c281374202b8ab7ba32c190a1a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
