import { template as template_00fc3516af9a4c0ea8ecbf7e8ea78acc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_00fc3516af9a4c0ea8ecbf7e8ea78acc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
